import { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { StyledBackground } from '@joovence/ui/components/StyledBackground/StyledBackground';
import { Button } from '@joovence/ui/components/Button/Base/Button.styled';

export const Custom404: FC = () => {
  const { t } = useTranslation('misc');
  const { back } = useRouter();

  return (
    <StyledBackground centerChildren fullCover>
      <h1>{t('404.header')}</h1>
      <Button onClick={back} variant="lightgreen">
        {t('404.button')}
      </Button>
    </StyledBackground>
  );
};
